@use  "../../core-ui/_base"

//PORTFOLIO
.portfolio, .portfolio-main
    display: flex
    flex-direction: column
    align-items: left
    .portfolio-text
        padding-bottom: 1.5rem
    h2
        font-size: 2.5rem
        letter-spacing: 1px
    p
        font-size: 1rem
        line-height: 1.5rem
    @media (min-width: base.$sm-width)
        .portfolio-text
            padding-bottom: 2rem
        h2
            font-size: 4rem
.portfolio-main
    grid-column: span 3
    padding: 2rem
//PORTFOLIO GRID
.portfolio-grid
    max-width: 100%
    display: flex
    flex-direction: column
    gap: 2rem
    position: relative

    .project
        display: grid
        grid-template-columns: 1fr
        position: relative
        gap: 1rem
        align-items: left
        .project-meta-stack
            display: flex
            flex-direction: row
            flex-wrap: wrap
            gap: 1rem
            font-size: 1rem
            p
                font-size: 1rem
                padding: 0.4rem
                font-family: base.$title-font
                background-color: #b0b0b009
                color: base.$white
                backdrop-filter: blur(5px)
                border-radius: 5px
                letter-spacing: 0.5px
                initial-letter: 1px
                margin: 0
    .project-description
        padding: 1rem
        display: flex
        flex-direction: column
        gap: 2rem
        p
            font-size: 1rem
            line-height: 1.5rem
    .project-img
        max-width: 100%
        object-fit: cover
        max-height: 100%
        transition: all 0.4s ease
        opacity: 0.7

    .project-links
        display: flex
        flex-direction: row
        gap: 2rem
        font-size: 1.5rem
        text-decoration: underline
        img
            width: 2.5rem
            height: 2.5rem
    @media (min-width: base.$md-width)
        .project
            grid-template-columns: 60% 1fr
            grid-template-rows: 1fr
            .project-description
                padding: 0
        .project-meta-stack
            flex-direction: row

    @media (min-width: base.$lg-width)
        .project
            grid-template-columns: repeat(2, 1fr)
            align-items: start
            .project-description
                align-items: flex-start
